import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import BirthdayUsersService from '@/services/birthdayUsers.service';
import TopUsersService from '@/services/topUsers.service';

const routes = [
  /*{
    path: '/auth/register',
    name: 'register',
    component: () => import('./views/auth/Register'),
    meta: { layout: 'empty-layout', guest: true },
  },*/
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('./views/auth/Login'),
    meta: { layout: 'empty-layout', guest: true },
  },
  {
    path: '/auth/verify-email',
    name: 'verifyEmail',
    component: () => import('./views/auth/VerifyEmail'),
    meta: { layout: 'empty-layout', guest: true },
    props: (route) => ({ token: route.query.token }),
  },
  /*{
    path: '/auth/request-password-reset',
    name: 'requestPasswordReset',
    component: () => import('./views/auth/RequestPasswordReset'),
    meta: { layout: 'empty-layout', guest: true },
  },
  {
    path: '/auth/password-reset',
    name: 'passwordReset',
    component: () => import('./views/auth/PasswordReset'),
    meta: { layout: 'empty-layout', guest: true },
    props: (route) => ({ token: route.query.token }),
  },*/

  {
    path: '/',
    name: 'dashboard',
    component: () => import('./views/Dashboard'),
    meta: { layout: 'main-layout', auth: true },
    //redirect: '/real-estate-lead',
  },

  // Роли
  {
    path: '/role',
    name: 'role',
    component: () => import('./views/role/Role'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRoleList'] },
  },
  {
    path: '/role/add',
    name: 'roleAdd',
    component: () => import('./views/role/AddRole'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRoleCreate'] },
  },
  {
    path: '/role/:id/update',
    name: 'roleUpdate',
    component: () => import('./views/role/RoleUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRoleUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Пользователи
  {
    path: '/user',
    name: 'user',
    component: () => import('./views/user/User'),
    meta: { layout: 'main-layout', auth: true, can: ['apiUserList'] },
  },
  {
    path: '/user/add',
    name: 'userAdd',
    component: () => import('./views/user/AddUser'),
    meta: { layout: 'main-layout', auth: true, can: ['apiUserCreate'] },
  },
  {
    path: '/user/:username/profile',
    name: 'userProfile',
    component: () => import('./views/user/UserProfile'),
    meta: { layout: 'main-layout', auth: true, can: ['apiUserUpdate','apiUserUpdateDepartment','apiUserUpdateOwner'] },
    props: (route) => ({ username: route.params.username }),
  },

  // Отделы
  {
    path: '/department',
    name: 'department',
    component: () => import('./views/department/Department'),
    meta: { layout: 'main-layout', auth: true, can: ['apiDepartmentList'] },
  },
  {
    path: '/department/add',
    name: 'departmentAdd',
    component: () => import('./views/department/AddDepartment'),
    meta: { layout: 'main-layout', auth: true, can: ['apiDepartmentCreate'] },
  },
  {
    path: '/department/:id/update',
    name: 'departmentUpdate',
    component: () => import('./views/department/DepartmentUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiDepartmentUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость
  {
    path: '/real-estate-lead',
    name: 'realEstateLead',
    component: () => import('./views/real-estate-lead/RealEstateLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadList','apiRealEstateLeadListCustomer','apiRealEstateLeadListDepartment','apiRealEstateLeadListOwner'] },
  },
  {
    path: '/real-estate-lead/add',
    name: 'realEstateLeadAdd',
    component: () => import('./views/real-estate-lead/AddRealEstateLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadCreate'] },
  },
  {
    path: '/real-estate-lead/compare',
    name: 'realEstateLeadCompare',
    component: () => import('./views/real-estate-lead/RealEstateLeadCompare'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadCompare'] },
  },
  {
    path: '/real-estate-lead/import',
    name: 'realEstateLeadImport',
    component: () => import('./views/real-estate-lead/RealEstateLeadImport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadImport'] },
  },
  {
    path: '/real-estate-lead/report',
    name: 'realEstateLeadReport',
    component: () => import('./views/real-estate-lead/RealEstateLeadReport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadReport','apiRealEstateLeadReportDepartment'] },
  },
  {
    path: '/real-estate-lead/report2',
    name: 'realEstateLeadReport2',
    component: () => import('./views/real-estate-lead/RealEstateLeadReport2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadReport2'] },
  },
  {
    path: '/real-estate-lead/report3',
    name: 'realEstateLeadReport3',
    component: () => import('./views/real-estate-lead/RealEstateLeadReport3'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadReport3','apiRealEstateLeadReport3Department'] },
  },
  {
    path: '/real-estate-lead/tournament',
    name: 'realEstateLeadTournament',
    component: () => import('./views/real-estate-lead/RealEstateLeadTournament'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadTournament'] },
  },
  {
    path: '/real-estate-lead/rating',
    name: 'realEstateLeadRating',
    component: () => import('./views/real-estate-lead/RealEstateLeadRating'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadRating'] },
  },
  {
    path: '/real-estate-lead/blacklist',
    name: 'realEstateLeadBlacklist',
    component: () => import('./views/real-estate-lead/RealEstateLeadBlacklist'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadBlacklist'] },
  },
  {
    path: '/real-estate-lead/object-map',
    name: 'realEstateLeadObjectMap',
    component: () => import('./views/real-estate-lead/RealEstateLeadObjectMap'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadObjectMap'] },
  },
  {
    path: '/real-estate-lead/object-audit-map',
    name: 'realEstateLeadObjectAuditMap',
    component: () => import('./views/real-estate-lead/RealEstateLeadObjectAuditMap'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateLeadObjectAuditMap'] },
  },

  // Недвижимость - Заказчики
  {
    path: '/real-estate-customer',
    name: 'realEstateCustomer',
    component: () => import('./views/real-estate-customer/RealEstateCustomer'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateCustomerList'] },
  },
  {
    path: '/real-estate-customer/add',
    name: 'realEstateCustomerAdd',
    component: () => import('./views/real-estate-customer/AddRealEstateCustomer'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateCustomerCreate'] },
  },
  {
    path: '/real-estate-customer/:id/update',
    name: 'realEstateCustomerUpdate',
    component: () => import('./views/real-estate-customer/RealEstateCustomerUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateCustomerUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Жилые комплексы
  {
    path: '/real-estate-residential-complex',
    name: 'realEstateResidentialComplex',
    component: () => import('./views/real-estate-residential-complex/RealEstateResidentialComplex'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexList'] },
  },
  {
    path: '/real-estate-residential-complex/add',
    name: 'realEstateResidentialComplexAdd',
    component: () => import('./views/real-estate-residential-complex/AddRealEstateResidentialComplex'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexCreate'] },
  },
  {
    path: '/real-estate-residential-complex/:id/update',
    name: 'realEstateResidentialComplexUpdate',
    component: () => import('./views/real-estate-residential-complex/RealEstateResidentialComplexUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Классы ЖК
  {
    path: '/real-estate-residential-complex-class',
    name: 'realEstateResidentialComplexClass',
    component: () => import('./views/real-estate-residential-complex-class/RealEstateResidentialComplexClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexClassList'] },
  },
  {
    path: '/real-estate-residential-complex-class/add',
    name: 'realEstateResidentialComplexClassAdd',
    component: () => import('./views/real-estate-residential-complex-class/AddRealEstateResidentialComplexClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexClassCreate'] },
  },
  {
    path: '/real-estate-residential-complex-class/:id/update',
    name: 'realEstateResidentialComplexClassUpdate',
    component: () => import('./views/real-estate-residential-complex-class/RealEstateResidentialComplexClassUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexClassUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Регионы ЖК
  {
    path: '/real-estate-residential-complex-region',
    name: 'realEstateResidentialComplexRegion',
    component: () => import('./views/real-estate-residential-complex-region/RealEstateResidentialComplexRegion'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexRegionList'] },
  },
  {
    path: '/real-estate-residential-complex-region/add',
    name: 'realEstateResidentialComplexRegionAdd',
    component: () => import('./views/real-estate-residential-complex-region/AddRealEstateResidentialComplexRegion'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexRegionCreate'] },
  },
  {
    path: '/real-estate-residential-complex-region/:id/update',
    name: 'realEstateResidentialComplexRegionUpdate',
    component: () => import('./views/real-estate-residential-complex-region/RealEstateResidentialComplexRegionUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateResidentialComplexRegionUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Застройщики
  {
    path: '/real-estate-builder',
    name: 'realEstateBuilder',
    component: () => import('./views/real-estate-builder/RealEstateBuilder'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateBuilderList'] },
  },
  {
    path: '/real-estate-builder/add',
    name: 'realEstateBuilderAdd',
    component: () => import('./views/real-estate-builder/AddRealEstateBuilder'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateBuilderCreate'] },
  },
  {
    path: '/real-estate-builder/:id/update',
    name: 'realEstateBuilderUpdate',
    component: () => import('./views/real-estate-builder/RealEstateBuilderUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateBuilderUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Объекты
  {
    path: '/real-estate-object',
    name: 'realEstateObject',
    component: () => import('./views/real-estate-object/RealEstateObject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateObjectList'] },
  },
  {
    path: '/real-estate-object/add',
    name: 'realEstateObjectAdd',
    component: () => import('./views/real-estate-object/AddRealEstateObject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateObjectCreate'] },
  },
  {
    path: '/real-estate-object/:id/update',
    name: 'realEstateObjectUpdate',
    component: () => import('./views/real-estate-object/RealEstateObjectUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateObjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Кнопки объектов
  {
    path: '/real-estate-object-button',
    name: 'realEstateObjectButton',
    component: () => import('./views/real-estate-object-button/RealEstateObjectButton'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateObjectList'] },
  },
  {
    path: '/real-estate-object-button/add',
    name: 'realEstateObjectButtonAdd',
    component: () => import('./views/real-estate-object-button/AddRealEstateObjectButton'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateObjectCreate'] },
  },
  {
    path: '/real-estate-object-button/:id/update',
    name: 'realEstateObjectButtonUpdate',
    component: () => import('./views/real-estate-object-button/RealEstateObjectButtonUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateObjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Нормативные документы
  {
    path: '/real-estate-regulation',
    name: 'realEstateRegulation',
    component: () => import('./views/real-estate-regulation/RealEstateRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateRegulationList'] },
  },
  {
    path: '/real-estate-regulation/add',
    name: 'realEstateRegulationAdd',
    component: () => import('./views/real-estate-regulation/AddRealEstateRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateRegulationCreate'] },
  },
  {
    path: '/real-estate-regulation/:id/update',
    name: 'realEstateRegulationUpdate',
    component: () => import('./views/real-estate-regulation/RealEstateRegulationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateRegulationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Откуда лид
  {
    path: '/real-estate-where-lead',
    name: 'realEstateWhereLead',
    component: () => import('./views/real-estate-where-lead/RealEstateWhereLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateWhereLeadList'] },
  },
  {
    path: '/real-estate-where-lead/add',
    name: 'realEstateWhereLeadAdd',
    component: () => import('./views/real-estate-where-lead/AddRealEstateWhereLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateWhereLeadCreate'] },
  },
  {
    path: '/real-estate-where-lead/:id/update',
    name: 'realEstateWhereLeadUpdate',
    component: () => import('./views/real-estate-where-lead/RealEstateWhereLeadUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateWhereLeadUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Статусы РК
  {
    path: '/real-estate-status-rk',
    name: 'realEstateStatusRk',
    component: () => import('./views/real-estate-status-rk/RealEstateStatusRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateStatusRkList'] },
  },
  {
    path: '/real-estate-status-rk/add',
    name: 'realEstateStatusRkAdd',
    component: () => import('./views/real-estate-status-rk/AddRealEstateStatusRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateStatusRkCreate'] },
  },
  {
    path: '/real-estate-status-rk/:id/update',
    name: 'realEstateStatusRkUpdate',
    component: () => import('./views/real-estate-status-rk/RealEstateStatusRkUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateStatusRkUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Классы
  {
    path: '/real-estate-class',
    name: 'realEstateClass',
    component: () => import('./views/real-estate-class/RealEstateClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateClassList'] },
  },
  {
    path: '/real-estate-class/add',
    name: 'realEstateClassAdd',
    component: () => import('./views/real-estate-class/AddRealEstateClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateClassCreate'] },
  },
  {
    path: '/real-estate-class/:id/update',
    name: 'realEstateClassUpdate',
    component: () => import('./views/real-estate-class/RealEstateClassUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateClassUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Коэффициенты РК
  /*{
    path: '/real-estate-koeff-rk',
    name: 'realEstateKoeffRk',
    component: () => import('./views/real-estate-koeff-rk/RealEstateKoeffRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateKoeffRkList'] },
  },
  {
    path: '/real-estate-koeff-rk/add',
    name: 'realEstateKoeffRkAdd',
    component: () => import('./views/real-estate-koeff-rk/AddRealEstateKoeffRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateKoeffRkCreate'] },
  },
  {
    path: '/real-estate-koeff-rk/:id/update',
    name: 'realEstateKoeffRkUpdate',
    component: () => import('./views/real-estate-koeff-rk/RealEstateKoeffRkUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateKoeffRkUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },*/

  // Партнер
  {
    path: '/partner-lead',
    name: 'partnerLead',
    component: () => import('./views/partner-lead/PartnerLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadList','apiPartnerLeadListCustomer','apiPartnerLeadListDepartment','apiPartnerLeadListOwner'] },
  },
  {
    path: '/partner-lead/add',
    name: 'partnerLeadAdd',
    component: () => import('./views/partner-lead/AddPartnerLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadCreate'] },
  },
  {
    path: '/partner-lead/compare',
    name: 'partnerLeadCompare',
    component: () => import('./views/partner-lead/PartnerLeadCompare'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadCompare'] },
  },
  {
    path: '/partner-lead/import',
    name: 'partnerLeadImport',
    component: () => import('./views/partner-lead/PartnerLeadImport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadImport'] },
  },
  {
    path: '/partner-lead/report',
    name: 'partnerLeadReport',
    component: () => import('./views/partner-lead/PartnerLeadReport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadReport','apiPartnerLeadReportDepartment'] },
  },
  {
    path: '/partner-lead/report2',
    name: 'partnerLeadReport2',
    component: () => import('./views/partner-lead/PartnerLeadReport2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadReport2'] },
  },
  {
    path: '/partner-lead/report3',
    name: 'partnerLeadReport3',
    component: () => import('./views/partner-lead/PartnerLeadReport3'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadReport3'] },
  },
  {
    path: '/partner-lead/blacklist',
    name: 'partnerLeadBlacklist',
    component: () => import('./views/partner-lead/PartnerLeadBlacklist'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadBlacklist'] },
  },
  {
    path: '/partner-lead/object-map',
    name: 'partnerLeadObjectMap',
    component: () => import('./views/partner-lead/PartnerLeadObjectMap'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadObjectMap'] },
  },
  {
    path: '/partner-lead/object-audit-map',
    name: 'partnerLeadObjectAuditMap',
    component: () => import('./views/partner-lead/PartnerLeadObjectAuditMap'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerLeadObjectAuditMap'] },
  },

  // Партнер - Заказчики
  {
    path: '/partner-customer',
    name: 'partnerCustomer',
    component: () => import('./views/partner-customer/PartnerCustomer'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerCustomerList'] },
  },
  {
    path: '/partner-customer/add',
    name: 'partnerCustomerAdd',
    component: () => import('./views/partner-customer/AddPartnerCustomer'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerCustomerCreate'] },
  },
  {
    path: '/partner-customer/:id/update',
    name: 'partnerCustomerUpdate',
    component: () => import('./views/partner-customer/PartnerCustomerUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerCustomerUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Жилые комплексы
  {
    path: '/partner-residential-complex',
    name: 'partnerResidentialComplex',
    component: () => import('./views/partner-residential-complex/PartnerResidentialComplex'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerResidentialComplexList'] },
  },
  {
    path: '/partner-residential-complex/add',
    name: 'partnerResidentialComplexAdd',
    component: () => import('./views/partner-residential-complex/AddPartnerResidentialComplex'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerResidentialComplexCreate'] },
  },
  {
    path: '/partner-residential-complex/:id/update',
    name: 'partnerResidentialComplexUpdate',
    component: () => import('./views/partner-residential-complex/PartnerResidentialComplexUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerResidentialComplexUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Классы ЖК
  {
    path: '/partner-residential-complex-class',
    name: 'partnerResidentialComplexClass',
    component: () => import('./views/partner-residential-complex-class/PartnerResidentialComplexClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerResidentialComplexClassList'] },
  },
  {
    path: '/partner-residential-complex-class/add',
    name: 'partnerResidentialComplexClassAdd',
    component: () => import('./views/partner-residential-complex-class/AddPartnerResidentialComplexClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerResidentialComplexClassCreate'] },
  },
  {
    path: '/partner-residential-complex-class/:id/update',
    name: 'partnerResidentialComplexClassUpdate',
    component: () => import('./views/partner-residential-complex-class/PartnerResidentialComplexClassUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerResidentialComplexClassUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Застройщики
  {
    path: '/partner-builder',
    name: 'partnerBuilder',
    component: () => import('./views/partner-builder/PartnerBuilder'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerBuilderList'] },
  },
  {
    path: '/partner-builder/add',
    name: 'partnerBuilderAdd',
    component: () => import('./views/partner-builder/AddPartnerBuilder'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerBuilderCreate'] },
  },
  {
    path: '/partner-builder/:id/update',
    name: 'partnerBuilderUpdate',
    component: () => import('./views/partner-builder/PartnerBuilderUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerBuilderUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Объекты
  {
    path: '/partner-object',
    name: 'partnerObject',
    component: () => import('./views/partner-object/PartnerObject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerObjectList'] },
  },
  {
    path: '/partner-object/add',
    name: 'partnerObjectAdd',
    component: () => import('./views/partner-object/AddPartnerObject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerObjectCreate'] },
  },
  {
    path: '/partner-object/:id/update',
    name: 'partnerObjectUpdate',
    component: () => import('./views/partner-object/PartnerObjectUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerObjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Кнопки объектов
  {
    path: '/partner-object-button',
    name: 'partnerObjectButton',
    component: () => import('./views/partner-object-button/PartnerObjectButton'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerObjectList'] },
  },
  {
    path: '/partner-object-button/add',
    name: 'partnerObjectButtonAdd',
    component: () => import('./views/partner-object-button/AddPartnerObjectButton'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerObjectCreate'] },
  },
  {
    path: '/partner-object-button/:id/update',
    name: 'partnerObjectButtonUpdate',
    component: () => import('./views/partner-object-button/PartnerObjectButtonUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerObjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Нормативные документы
  {
    path: '/partner-regulation',
    name: 'partnerRegulation',
    component: () => import('./views/partner-regulation/PartnerRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerRegulationList'] },
  },
  {
    path: '/partner-regulation/add',
    name: 'partnerRegulationAdd',
    component: () => import('./views/partner-regulation/AddPartnerRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerRegulationCreate'] },
  },
  {
    path: '/partner-regulation/:id/update',
    name: 'partnerRegulationUpdate',
    component: () => import('./views/partner-regulation/PartnerRegulationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerRegulationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Откуда лид
  {
    path: '/partner-where-lead',
    name: 'partnerWhereLead',
    component: () => import('./views/partner-where-lead/PartnerWhereLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerWhereLeadList'] },
  },
  {
    path: '/partner-where-lead/add',
    name: 'partnerWhereLeadAdd',
    component: () => import('./views/partner-where-lead/AddPartnerWhereLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerWhereLeadCreate'] },
  },
  {
    path: '/partner-where-lead/:id/update',
    name: 'partnerWhereLeadUpdate',
    component: () => import('./views/partner-where-lead/PartnerWhereLeadUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerWhereLeadUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Статусы РК
  {
    path: '/partner-status-rk',
    name: 'partnerStatusRk',
    component: () => import('./views/partner-status-rk/PartnerStatusRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerStatusRkList'] },
  },
  {
    path: '/partner-status-rk/add',
    name: 'partnerStatusRkAdd',
    component: () => import('./views/partner-status-rk/AddPartnerStatusRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerStatusRkCreate'] },
  },
  {
    path: '/partner-status-rk/:id/update',
    name: 'partnerStatusRkUpdate',
    component: () => import('./views/partner-status-rk/PartnerStatusRkUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerStatusRkUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Недвижимость - Классы
  {
    path: '/auto-class',
    name: 'autoClass',
    component: () => import('./views/auto-class/AutoClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoClassList'] },
  },
  {
    path: '/auto-class/add',
    name: 'autoClassAdd',
    component: () => import('./views/auto-class/AddAutoClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoClassCreate'] },
  },
  {
    path: '/auto-class/:id/update',
    name: 'autoClassUpdate',
    component: () => import('./views/auto-class/AutoClassUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoClassUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Партнер - Коэффициенты РК
  /*{
    path: '/partner-koeff-rk',
    name: 'partnerKoeffRk',
    component: () => import('./views/partner-koeff-rk/PartnerKoeffRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerKoeffRkList'] },
  },
  {
    path: '/partner-koeff-rk/add',
    name: 'partnerKoeffRkAdd',
    component: () => import('./views/partner-koeff-rk/AddPartnerKoeffRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerKoeffRkCreate'] },
  },
  {
    path: '/partner-koeff-rk/:id/update',
    name: 'partnerKoeffRkUpdate',
    component: () => import('./views/partner-koeff-rk/PartnerKoeffRkUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiPartnerKoeffRkUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },*/

  // Авто
  {
    path: '/auto-lead',
    name: 'autoLead',
    component: () => import('./views/auto-lead/AutoLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadList','apiAutoLeadListCustomer','apiAutoLeadListDepartment','apiAutoLeadListOwner'] },
  },
  {
    path: '/auto-lead/add',
    name: 'autoLeadAdd',
    component: () => import('./views/auto-lead/AddAutoLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadCreate'] },
  },
  {
    path: '/auto-lead/compare',
    name: 'autoLeadCompare',
    component: () => import('./views/auto-lead/AutoLeadCompare'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadCompare'] },
  },
  {
    path: '/auto-lead/import',
    name: 'autoLeadImport',
    component: () => import('./views/auto-lead/AutoLeadImport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadImport'] },
  },
  {
    path: '/auto-lead/report',
    name: 'autoLeadReport',
    component: () => import('./views/auto-lead/AutoLeadReport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadReport','apiAutoLeadReportDepartment'] },
  },
  {
    path: '/auto-lead/report2',
    name: 'autoLeadReport2',
    component: () => import('./views/auto-lead/AutoLeadReport2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadReport2'] },
  },
  {
    path: '/auto-lead/report3',
    name: 'autoLeadReport3',
    component: () => import('./views/auto-lead/AutoLeadReport3'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadReport3','apiAutoLeadReport3Department'] },
  },
  {
    path: '/auto-lead/tournament',
    name: 'autoLeadTournament',
    component: () => import('./views/auto-lead/AutoLeadTournament'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadTournament'] },
  },
  {
    path: '/auto-lead/rating',
    name: 'autoLeadRating',
    component: () => import('./views/auto-lead/AutoLeadRating'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadRating'] },
  },
  {
    path: '/auto-lead/blacklist',
    name: 'autoLeadBlacklist',
    component: () => import('./views/auto-lead/AutoLeadBlacklist'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadBlacklist'] },
  },
  {
    path: '/auto-lead/object-map',
    name: 'autoLeadObjectMap',
    component: () => import('./views/auto-lead/AutoLeadObjectMap'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadObjectMap'] },
  },
  {
    path: '/auto-lead/object-audit-map',
    name: 'autoLeadObjectAuditMap',
    component: () => import('./views/auto-lead/AutoLeadObjectAuditMap'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoLeadObjectAuditMap'] },
  },

  // Авто - Заказчики
  {
    path: '/auto-customer',
    name: 'autoCustomer',
    component: () => import('./views/auto-customer/AutoCustomer'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCustomerList'] },
  },
  {
    path: '/auto-customer/add',
    name: 'autoCustomerAdd',
    component: () => import('./views/auto-customer/AddAutoCustomer'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCustomerCreate'] },
  },
  {
    path: '/auto-customer/:id/update',
    name: 'autoCustomerUpdate',
    component: () => import('./views/auto-customer/AutoCustomerUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCustomerUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Проекты
  {
    path: '/auto-project',
    name: 'autoProject',
    component: () => import('./views/auto-project/AutoProject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectList'] },
  },
  {
    path: '/auto-project/add',
    name: 'autoProjectAdd',
    component: () => import('./views/auto-project/AddAutoProject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectCreate'] },
  },
  {
    path: '/auto-project/:id/update',
    name: 'autoProjectUpdate',
    component: () => import('./views/auto-project/AutoProjectUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Классы авто
  {
    path: '/auto-project-class',
    name: 'autoProjectClass',
    component: () => import('./views/auto-project-class/AutoProjectClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectClassList'] },
  },
  {
    path: '/auto-project-class/add',
    name: 'autoProjectClassAdd',
    component: () => import('./views/auto-project-class/AddAutoProjectClass'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectClassCreate'] },
  },
  {
    path: '/auto-project-class/:id/update',
    name: 'autoProjectClassUpdate',
    component: () => import('./views/auto-project-class/AutoProjectClassUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectClassUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Регионы
  {
    path: '/auto-project-region',
    name: 'autoProjectRegion',
    component: () => import('./views/auto-project-region/AutoProjectRegion'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectRegionList'] },
  },
  {
    path: '/auto-project-region/add',
    name: 'autoProjectRegionAdd',
    component: () => import('./views/auto-project-region/AddAutoProjectRegion'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectRegionCreate'] },
  },
  {
    path: '/auto-project-region/:id/update',
    name: 'autoProjectRegionUpdate',
    component: () => import('./views/auto-project-region/AutoProjectRegionUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoProjectRegionUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Объекты
  {
    path: '/auto-object',
    name: 'autoObject',
    component: () => import('./views/auto-object/AutoObject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoObjectList'] },
  },
  {
    path: '/auto-object/add',
    name: 'autoObjectAdd',
    component: () => import('./views/auto-object/AddAutoObject'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoObjectCreate'] },
  },
  {
    path: '/auto-object/:id/update',
    name: 'autoObjectUpdate',
    component: () => import('./views/auto-object/AutoObjectUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoObjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Кнопки объектов
  {
    path: '/auto-object-button',
    name: 'autoObjectButton',
    component: () => import('./views/auto-object-button/AutoObjectButton'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoObjectList'] },
  },
  {
    path: '/auto-object-button/add',
    name: 'autoObjectButtonAdd',
    component: () => import('./views/auto-object-button/AddAutoObjectButton'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoObjectCreate'] },
  },
  {
    path: '/auto-object-button/:id/update',
    name: 'autoObjectButtonUpdate',
    component: () => import('./views/auto-object-button/AutoObjectButtonUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoObjectUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Нормативные документы
  {
    path: '/auto-regulation',
    name: 'autoRegulation',
    component: () => import('./views/auto-regulation/AutoRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoRegulationList'] },
  },
  {
    path: '/auto-regulation/add',
    name: 'autoRegulationAdd',
    component: () => import('./views/auto-regulation/AddAutoRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoRegulationCreate'] },
  },
  {
    path: '/auto-regulation/:id/update',
    name: 'autoRegulationUpdate',
    component: () => import('./views/auto-regulation/AutoRegulationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoRegulationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Откуда лид
  {
    path: '/auto-where-lead',
    name: 'autoWhereLead',
    component: () => import('./views/auto-where-lead/AutoWhereLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoWhereLeadList'] },
  },
  {
    path: '/auto-where-lead/add',
    name: 'autoWhereLeadAdd',
    component: () => import('./views/auto-where-lead/AddAutoWhereLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoWhereLeadCreate'] },
  },
  {
    path: '/auto-where-lead/:id/update',
    name: 'autoWhereLeadUpdate',
    component: () => import('./views/auto-where-lead/AutoWhereLeadUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoWhereLeadUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Статусы РК
  {
    path: '/auto-status-rk',
    name: 'autoStatusRk',
    component: () => import('./views/auto-status-rk/AutoStatusRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoStatusRkList'] },
  },
  {
    path: '/auto-status-rk/add',
    name: 'autoStatusRkAdd',
    component: () => import('./views/auto-status-rk/AddAutoStatusRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoStatusRkCreate'] },
  },
  {
    path: '/auto-status-rk/:id/update',
    name: 'autoStatusRkUpdate',
    component: () => import('./views/auto-status-rk/AutoStatusRkUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoStatusRkUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Авто - Коэффициенты РК
  /*{
    path: '/auto-koeff-rk',
    name: 'autoKoeffRk',
    component: () => import('./views/auto-koeff-rk/AutoKoeffRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoKoeffRkList'] },
  },
  {
    path: '/auto-koeff-rk/add',
    name: 'autoKoeffRkAdd',
    component: () => import('./views/auto-koeff-rk/AddAutoKoeffRk'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoKoeffRkCreate'] },
  },
  {
    path: '/auto-koeff-rk/:id/update',
    name: 'autoKoeffRkUpdate',
    component: () => import('./views/auto-koeff-rk/AutoKoeffRkUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoKoeffRkUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },*/

  // HR отдел
  {
    path: '/hr-staff',
    name: 'hrStaff',
    component: () => import('./views/hr-staff/HrStaff'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaffList','apiHrStaffListOwner'] },
  },
  {
    path: '/hr-staff/add',
    name: 'hrStaffAdd',
    component: () => import('./views/hr-staff/AddHrStaff'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaffCreate'] },
  },
  {
    path: '/hr-staff/report',
    name: 'hrStaffReport',
    component: () => import('./views/hr-staff/HrStaffReport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaffReport'] },
  },
  {
    path: '/hr-staff/report2',
    name: 'hrStaffReport2',
    component: () => import('./views/hr-staff/HrStaffReport2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaffReport2'] },
  },
  {
    path: '/hr-staff/report3',
    name: 'hrStaffReport3',
    component: () => import('./views/hr-staff/HrStaffReport3'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaffReport3'] },
  },
  {
    path: '/hr-staff/report4',
    name: 'hrStaffReport4',
    component: () => import('./views/hr-staff/HrStaffReport4'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaffReport4'] },
  },

  // HR отдел 2
  {
    path: '/hr-staff2',
    name: 'hrStaff2',
    component: () => import('./views/hr-staff2/HrStaff2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaff2List','apiHrStaff2ListOwner'] },
  },
  {
    path: '/hr-staff2/add',
    name: 'hrStaff2Add',
    component: () => import('./views/hr-staff2/AddHrStaff2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaff2Create'] },
  },
  {
    path: '/hr-staff2/report',
    name: 'hrStaff2Report',
    component: () => import('./views/hr-staff2/HrStaff2Report'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaff2Report'] },
  },
  {
    path: '/hr-staff2/report2',
    name: 'hrStaff2Report2',
    component: () => import('./views/hr-staff2/HrStaff2Report2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaff2Report2'] },
  },
  {
    path: '/hr-staff2/report3',
    name: 'hrStaff2Report3',
    component: () => import('./views/hr-staff2/HrStaff2Report3'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaff2Report3'] },
  },
  {
    path: '/hr-staff2/report4',
    name: 'hrStaff2Report4',
    component: () => import('./views/hr-staff2/HrStaff2Report4'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStaff2Report4'] },
  },

  // HR отдел - Рекламный канал
  {
    path: '/hr-advert-channel',
    name: 'hrAdvertChannel',
    component: () => import('./views/hr-advert-channel/HrAdvertChannel'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrAdvertChannelList'] },
  },
  {
    path: '/hr-advert-channel/add',
    name: 'hrAdvertChannelAdd',
    component: () => import('./views/hr-advert-channel/AddHrAdvertChannel'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrAdvertChannelCreate'] },
  },
  {
    path: '/hr-advert-channel/:id/update',
    name: 'hrAdvertChannelUpdate',
    component: () => import('./views/hr-advert-channel/HrAdvertChannelUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrAdvertChannelUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Руководители
  {
    path: '/hr-supervisor',
    name: 'hrSupervisor',
    component: () => import('./views/hr-supervisor/HrSupervisor'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrSupervisorList'] },
  },
  {
    path: '/hr-supervisor/add',
    name: 'hrSupervisorAdd',
    component: () => import('./views/hr-supervisor/AddHrSupervisor'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrSupervisorCreate'] },
  },
  {
    path: '/hr-supervisor/:id/update',
    name: 'hrSupervisorUpdate',
    component: () => import('./views/hr-supervisor/HrSupervisorUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrSupervisorUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Смены
  {
    path: '/hr-shift',
    name: 'hrShift',
    component: () => import('./views/hr-shift/HrShift'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrShiftList'] },
  },
  {
    path: '/hr-shift/add',
    name: 'hrShiftAdd',
    component: () => import('./views/hr-shift/AddHrShift'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrShiftCreate'] },
  },
  {
    path: '/hr-shift/:id/update',
    name: 'hrShiftUpdate',
    component: () => import('./views/hr-shift/HrShiftUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrShiftUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Записался на обучение
  {
    path: '/hr-signed-up-interview',
    name: 'hrSignedUpInterview',
    component: () => import('./views/hr-signed-up-interview/HrSignedUpInterview'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrSignedUpInterviewList'] },
  },
  {
    path: '/hr-signed-up-interview/add',
    name: 'hrSignedUpInterviewAdd',
    component: () => import('./views/hr-signed-up-interview/AddHrSignedUpInterview'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrSignedUpInterviewCreate'] },
  },
  {
    path: '/hr-signed-up-interview/:id/update',
    name: 'hrSignedUpInterviewUpdate',
    component: () => import('./views/hr-signed-up-interview/HrSignedUpInterviewUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrSignedUpInterviewUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Причина отказа
  {
    path: '/hr-reason-refusal',
    name: 'hrReasonRefusal',
    component: () => import('./views/hr-reason-refusal/HrReasonRefusal'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrReasonRefusalList'] },
  },
  {
    path: '/hr-reason-refusal/add',
    name: 'hrReasonRefusalAdd',
    component: () => import('./views/hr-reason-refusal/AddHrReasonRefusal'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrReasonRefusalCreate'] },
  },
  {
    path: '/hr-reason-refusal/:id/update',
    name: 'hrReasonRefusalUpdate',
    component: () => import('./views/hr-reason-refusal/HrReasonRefusalUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrReasonRefusalUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Статусы адаптации
  {
    path: '/hr-passed-adaptation',
    name: 'hrPassedAdaptation',
    component: () => import('./views/hr-passed-adaptation/HrPassedAdaptation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrPassedAdaptationList'] },
  },
  {
    path: '/hr-passed-adaptation/add',
    name: 'hrPassedAdaptationAdd',
    component: () => import('./views/hr-passed-adaptation/AddHrPassedAdaptation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrPassedAdaptationCreate'] },
  },
  {
    path: '/hr-passed-adaptation/:id/update',
    name: 'hrPassedAdaptationUpdate',
    component: () => import('./views/hr-passed-adaptation/HrPassedAdaptationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrPassedAdaptationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Статус HR
  {
    path: '/hr-status',
    name: 'hrStatus',
    component: () => import('./views/hr-status/HrStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusList'] },
  },
  {
    path: '/hr-status/add',
    name: 'hrStatusAdd',
    component: () => import('./views/hr-status/AddHrStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusCreate'] },
  },
  {
    path: '/hr-status/:id/update',
    name: 'hrStatusUpdate',
    component: () => import('./views/hr-status/HrStatusUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Статус обучения
  {
    path: '/hr-status-training',
    name: 'hrStatusTraining',
    component: () => import('./views/hr-status-training/HrStatusTraining'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusTrainingList'] },
  },
  {
    path: '/hr-status-training/add',
    name: 'hrStatusTrainingAdd',
    component: () => import('./views/hr-status-training/AddHrStatusTraining'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusTrainingCreate'] },
  },
  {
    path: '/hr-status-training/:id/update',
    name: 'hrStatusTrainingUpdate',
    component: () => import('./views/hr-status-training/HrStatusTrainingUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusTrainingUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // HR отдел - Итоговый статус
  {
    path: '/hr-status-total',
    name: 'hrStatusTotal',
    component: () => import('./views/hr-status-total/HrStatusTotal'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusTotalList'] },
  },
  {
    path: '/hr-status-total/add',
    name: 'hrStatusTotalAdd',
    component: () => import('./views/hr-status-total/AddHrStatusTotal'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusTotalCreate'] },
  },
  {
    path: '/hr-status-total/:id/update',
    name: 'hrStatusTotalUpdate',
    component: () => import('./views/hr-status-total/HrStatusTotalUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiHrStatusTotalUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость
  {
    path: '/callback-lead',
    name: 'callbackLead',
    component: () => import('./views/callback-lead/CallbackLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackLeadList','apiCallbackLeadListOwner'] },
  },
  {
    path: '/callback-lead/add',
    name: 'callbackLeadAdd',
    component: () => import('./views/callback-lead/AddCallbackLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackLeadCreate'] },
  },

  // Перезвон Недвижимость - Бюджет
  {
    path: '/callback-budget',
    name: 'callbackBudget',
    component: () => import('./views/callback-budget/CallbackBudget'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackBudgetList'] },
  },
  {
    path: '/callback-budget/add',
    name: 'callbackBudgetAdd',
    component: () => import('./views/callback-budget/AddCallbackBudget'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackBudgetCreate'] },
  },
  {
    path: '/callback-budget/:id/update',
    name: 'callbackBudgetUpdate',
    component: () => import('./views/callback-budget/CallbackBudgetUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackBudgetUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость - Направление
  {
    path: '/callback-direction',
    name: 'callbackDirection',
    component: () => import('./views/callback-direction/CallbackDirection'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDirectionList'] },
  },
  {
    path: '/callback-direction/add',
    name: 'callbackDirectionAdd',
    component: () => import('./views/callback-direction/AddCallbackDirection'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDirectionCreate'] },
  },
  {
    path: '/callback-direction/:id/update',
    name: 'callbackDirectionUpdate',
    component: () => import('./views/callback-direction/CallbackDirectionUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDirectionUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость - Локация
  {
    path: '/callback-location',
    name: 'callbackLocation',
    component: () => import('./views/callback-location/CallbackLocation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackLocationList'] },
  },
  {
    path: '/callback-location/add',
    name: 'callbackLocationAdd',
    component: () => import('./views/callback-location/AddCallbackLocation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackLocationCreate'] },
  },
  {
    path: '/callback-location/:id/update',
    name: 'callbackLocationUpdate',
    component: () => import('./views/callback-location/CallbackLocationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackLocationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость - Количество комнат
  {
    path: '/callback-quantity-rooms',
    name: 'callbackQuantityRooms',
    component: () => import('./views/callback-quantity-rooms/CallbackQuantityRooms'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackQuantityRoomsList'] },
  },
  {
    path: '/callback-quantity-rooms/add',
    name: 'callbackQuantityRoomsAdd',
    component: () => import('./views/callback-quantity-rooms/AddCallbackQuantityRooms'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackQuantityRoomsCreate'] },
  },
  {
    path: '/callback-quantity-rooms/:id/update',
    name: 'callbackQuantityRoomsUpdate',
    component: () => import('./views/callback-quantity-rooms/CallbackQuantityRoomsUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackQuantityRoomsUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость - Отделка
  {
    path: '/callback-decoration',
    name: 'callbackDecoration',
    component: () => import('./views/callback-decoration/CallbackDecoration'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDecorationList'] },
  },
  {
    path: '/callback-decoration/add',
    name: 'callbackDecorationAdd',
    component: () => import('./views/callback-decoration/AddCallbackDecoration'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDecorationCreate'] },
  },
  {
    path: '/callback-decoration/:id/update',
    name: 'callbackDecorationUpdate',
    component: () => import('./views/callback-decoration/CallbackDecorationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDecorationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость - Тип
  {
    path: '/callback-type',
    name: 'callbackType',
    component: () => import('./views/callback-type/CallbackType'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackTypeList'] },
  },
  {
    path: '/callback-type/add',
    name: 'callbackTypeAdd',
    component: () => import('./views/callback-type/AddCallbackType'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackTypeCreate'] },
  },
  {
    path: '/callback-type/:id/update',
    name: 'callbackTypeUpdate',
    component: () => import('./views/callback-type/CallbackTypeUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackTypeUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Недвижимость - Срок сдачи
  {
    path: '/callback-deadline',
    name: 'callbackDeadline',
    component: () => import('./views/callback-deadline/CallbackDeadline'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDeadlineList'] },
  },
  {
    path: '/callback-deadline/add',
    name: 'callbackDeadlineAdd',
    component: () => import('./views/callback-deadline/AddCallbackDeadline'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDeadlineCreate'] },
  },
  {
    path: '/callback-deadline/:id/update',
    name: 'callbackDeadlineUpdate',
    component: () => import('./views/callback-deadline/CallbackDeadlineUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCallbackDeadlineUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Авто
  {
    path: '/auto-callback-lead',
    name: 'autoCallbackLead',
    component: () => import('./views/auto-callback-lead/AutoCallbackLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackLeadList','apiAutoCallbackLeadListOwner'] },
  },
  {
    path: '/auto-callback-lead/add',
    name: 'autoCallbackLeadAdd',
    component: () => import('./views/auto-callback-lead/AddAutoCallbackLead'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackLeadCreate'] },
  },

  // Перезвон Авто - Бюджет
  {
    path: '/auto-callback-budget',
    name: 'autoCallbackBudget',
    component: () => import('./views/auto-callback-budget/AutoCallbackBudget'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackBudgetList'] },
  },
  {
    path: '/auto-callback-budget/add',
    name: 'autoCallbackBudgetAdd',
    component: () => import('./views/auto-callback-budget/AddAutoCallbackBudget'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackBudgetCreate'] },
  },
  {
    path: '/auto-callback-budget/:id/update',
    name: 'autoCallbackBudgetUpdate',
    component: () => import('./views/auto-callback-budget/AutoCallbackBudgetUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackBudgetUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Авто - Локация
  {
    path: '/auto-callback-location',
    name: 'autoCallbackLocation',
    component: () => import('./views/auto-callback-location/AutoCallbackLocation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackLocationList'] },
  },
  {
    path: '/auto-callback-location/add',
    name: 'autoCallbackLocationAdd',
    component: () => import('./views/auto-callback-location/AddAutoCallbackLocation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackLocationCreate'] },
  },
  {
    path: '/auto-callback-location/:id/update',
    name: 'autoCallbackLocationUpdate',
    component: () => import('./views/auto-callback-location/AutoCallbackLocationUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackLocationUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Перезвон Авто - Тип
  {
    path: '/auto-callback-type',
    name: 'autoCallbackType',
    component: () => import('./views/auto-callback-type/AutoCallbackType'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackTypeList'] },
  },
  {
    path: '/auto-callback-type/add',
    name: 'autoCallbackTypeAdd',
    component: () => import('./views/auto-callback-type/AddAutoCallbackType'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackTypeCreate'] },
  },
  {
    path: '/auto-callback-type/:id/update',
    name: 'autoCallbackTypeUpdate',
    component: () => import('./views/auto-callback-type/AutoCallbackTypeUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoCallbackTypeUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Объемы Недвижимость
  {
    path: '/real-estate-volume',
    name: 'realEstateVolume',
    component: () => import('./views/real-estate-volume/RealEstateVolume'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateVolumeList'] },
  },
  {
    path: '/real-estate-volume/report',
    name: 'realEstateVolumeReport',
    component: () => import('./views/real-estate-volume/RealEstateVolumeReport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateVolumeReport'] },
  },
  {
    path: '/real-estate-volume/report2',
    name: 'realEstateVolumeReport2',
    component: () => import('./views/real-estate-volume/RealEstateVolumeReport2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateVolumeReport2'] },
  },

  // Объемы Авто
  {
    path: '/auto-volume',
    name: 'autoVolume',
    component: () => import('./views/auto-volume/AutoVolume'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoVolumeList'] },
  },
  {
    path: '/auto-volume/report',
    name: 'autoVolumeReport',
    component: () => import('./views/auto-volume/AutoVolumeReport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoVolumeReport'] },
  },
  {
    path: '/auto-volume/report2',
    name: 'autoVolumeReport2',
    component: () => import('./views/auto-volume/AutoVolumeReport2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoVolumeReport2'] },
  },

  // Базы
  /*{
    path: '/base',
    name: 'base',
    component: () => import('./views/base/Base'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseList'] },
  },
  {
    path: '/base/compare',
    name: 'baseCompare',
    component: () => import('./views/base/BaseCompare'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseCompare'] },
  },
  {
    path: '/base/import',
    name: 'baseImport',
    component: () => import('./views/base/BaseImport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseImport'] },
  },*/

  // Базы Недвижимость
  {
    path: '/real-estate-base',
    name: 'realEstateBase',
    component: () => import('./views/real-estate-base/RealEstateBase'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateBaseList'] },
  },
  {
    path: '/real-estate-base/compare',
    name: 'realEstateBaseCompare',
    component: () => import('./views/real-estate-base/RealEstateBaseCompare'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateBaseCompare'] },
  },
  {
    path: '/real-estate-base/import',
    name: 'realEstateBaseImport',
    component: () => import('./views/real-estate-base/RealEstateBaseImport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateBaseImport'] },
  },

  // Базы Авто
  {
    path: '/auto-base',
    name: 'autoBase',
    component: () => import('./views/auto-base/AutoBase'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoBaseList'] },
  },
  {
    path: '/auto-base/compare',
    name: 'autoBaseCompare',
    component: () => import('./views/auto-base/AutoBaseCompare'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoBaseCompare'] },
  },
  {
    path: '/auto-base/import',
    name: 'autoBaseImport',
    component: () => import('./views/auto-base/AutoBaseImport'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoBaseImport'] },
  },

  // Базы - Источник баз
  {
    path: '/base-source',
    name: 'baseSource',
    component: () => import('./views/base-source/BaseSource'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseSourceList'] },
  },
  {
    path: '/base-source/add',
    name: 'baseSourceAdd',
    component: () => import('./views/base-source/AddBaseSource'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseSourceCreate'] },
  },
  {
    path: '/base-source/:id/update',
    name: 'baseSourceUpdate',
    component: () => import('./views/base-source/BaseSourceUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseSourceUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Базы - Регионы баз
  {
    path: '/base-region',
    name: 'baseRegion',
    component: () => import('./views/base-region/BaseRegion'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseRegionList'] },
  },
  {
    path: '/base-region/add',
    name: 'baseRegionAdd',
    component: () => import('./views/base-region/AddBaseRegion'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseRegionCreate'] },
  },
  {
    path: '/base-region/:id/update',
    name: 'baseRegionUpdate',
    component: () => import('./views/base-region/BaseRegionUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiBaseRegionUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Циан
  {
    path: '/cian/newbuilding',
    name: 'cianNewbuilding',
    component: () => import('./views/cian/CianNewbuilding'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCian'] },
  },
  {
    path: '/cian/price',
    name: 'cianPrice',
    component: () => import('./views/cian/CianPrice'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCian'] },
  },

  // Нормативные документы
  {
    path: '/regulation/real-estate',
    name: 'regulationRealEstate',
    component: () => import('./views/regulation/RealEstateRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiRealEstateRegulationList'] },
  },
  {
    path: '/regulation/auto',
    name: 'regulationAuto',
    component: () => import('./views/regulation/AutoRegulation'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAutoRegulationList'] },
  },

  // Статусы
  {
    path: '/status',
    name: 'status',
    component: () => import('./views/status/Status'),
    meta: { layout: 'main-layout', auth: true, can: ['apiStatusList'] },
  },
  {
    path: '/status/add',
    name: 'statusAdd',
    component: () => import('./views/status/AddStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiStatusCreate'] },
  },
  {
    path: '/status/:id/update',
    name: 'statusUpdate',
    component: () => import('./views/status/StatusUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiStatusUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Статусы заказчика
  {
    path: '/customer-status',
    name: 'customerStatus',
    component: () => import('./views/customer-status/CustomerStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCustomerStatusList'] },
  },
  {
    path: '/customer-status/add',
    name: 'customerStatusAdd',
    component: () => import('./views/customer-status/AddCustomerStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCustomerStatusCreate'] },
  },
  {
    path: '/customer-status/:id/update',
    name: 'customerStatusUpdate',
    component: () => import('./views/customer-status/CustomerStatusUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiCustomerStatusUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Статусы внутренний
  {
    path: '/internal-status',
    name: 'internalStatus',
    component: () => import('./views/internal-status/InternalStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiInternalStatusList'] },
  },
  {
    path: '/internal-status/add',
    name: 'internalStatusAdd',
    component: () => import('./views/internal-status/AddInternalStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiInternalStatusCreate'] },
  },
  {
    path: '/internal-status/:id/update',
    name: 'internalStatusUpdate',
    component: () => import('./views/internal-status/InternalStatusUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiInternalStatusUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Статусы аудитора 1
  {
    path: '/auditor-status',
    name: 'auditorStatus',
    component: () => import('./views/auditor-status/AuditorStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAuditorStatusList'] },
  },
  {
    path: '/auditor-status/add',
    name: 'auditorStatusAdd',
    component: () => import('./views/auditor-status/AddAuditorStatus'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAuditorStatusCreate'] },
  },
  {
    path: '/auditor-status/:id/update',
    name: 'auditorStatusUpdate',
    component: () => import('./views/auditor-status/AuditorStatusUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAuditorStatusUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Статусы аудитора 2
  {
    path: '/auditor-status2',
    name: 'auditorStatus2',
    component: () => import('./views/auditor-status2/AuditorStatus2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAuditorStatus2List'] },
  },
  {
    path: '/auditor-status2/add',
    name: 'auditorStatus2Add',
    component: () => import('./views/auditor-status2/AddAuditorStatus2'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAuditorStatus2Create'] },
  },
  {
    path: '/auditor-status2/:id/update',
    name: 'auditorStatus2Update',
    component: () => import('./views/auditor-status2/AuditorStatus2Update'),
    meta: { layout: 'main-layout', auth: true, can: ['apiAuditorStatus2Update'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Новичок
  {
    path: '/newbie',
    name: 'newbie',
    component: () => import('./views/newbie/Newbie'),
    meta: { layout: 'main-layout', auth: true, can: ['apiNewbie'] },
  },

  // Товары
  {
    path: '/product',
    name: 'product',
    component: () => import('./views/product/Product'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProductList'] },
  },
  {
    path: '/product/add',
    name: 'productAdd',
    component: () => import('./views/product/AddProduct'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProductCreate'] },
  },
  {
    path: '/product/:id/update',
    name: 'productUpdate',
    component: () => import('./views/product/ProductUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiProductUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Магазин
  {
    path: '/shop',
    name: 'shop',
    component: () => import('./views/shop/Shop'),
    meta: { layout: 'main-layout', auth: true, can: ['apiShop'] },
  },

  // Задачи
  {
    path: '/task',
    name: 'task',
    component: () => import('./views/task/Task'),
    meta: { layout: 'main-layout', auth: true, can: ['apiTaskList','apiTaskListOwner','apiTaskTake'] },
  },
  {
    path: '/task/add',
    name: 'taskAdd',
    component: () => import('./views/task/AddTask'),
    meta: { layout: 'main-layout', auth: true, can: ['apiTaskCreate'] },
  },
  {
    path: '/task/:id/update',
    name: 'taskUpdate',
    component: () => import('./views/task/TaskUpdate'),
    meta: { layout: 'main-layout', auth: true, can: ['apiTaskUpdate'] },
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: '/task/:id/view',
    name: 'taskView',
    component: () => import('./views/task/TaskView'),
    meta: { layout: 'main-layout', auth: true, can: ['apiTaskTake'] },
    props: (route) => ({ id: route.params.id }),
  },

  // Настройки
  {
    path: '/settings',
    name: 'settings',
    component: () => import('./views/settings/Settings'),
    meta: { layout: 'main-layout', auth: true, can: ['apiSettingsList'] },
  },

  // 404
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () => import('./views/NotFound'),
    meta: { layout: 'main-layout', auth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: '',
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');

  let user = null;
  try {
    user = JSON.parse(loggedIn);
  } catch (err) {
    user = null;
  }

  if (user) {
    if ((typeof from.name == 'undefined' || from.name == 'login') && to.name == 'dashboard') {
      let permissions1 = ['apiRealEstateLeadList','apiRealEstateLeadListCustomer','apiRealEstateLeadListDepartment','apiRealEstateLeadListOwner'];
      for (let i = 0; i < permissions1.length; i++) {
        if (user.permissions.indexOf(permissions1[i]) > -1) {
          next({ name: 'realEstateLead' });
          break;
        }
      }

      let permissions2 = ['apiAutoLeadList','apiAutoLeadListCustomer','apiAutoLeadListDepartment','apiAutoLeadListOwner'];
      for (let i = 0; i < permissions2.length; i++) {
        if (user.permissions.indexOf(permissions2[i]) > -1) {
          next({ name: 'autoLead' });
          break;
        }
      }
    }
  }

  if (to.matched.some(record => record.meta.auth)) { // Если маршрут только для авторизованых
    if (!loggedIn) { // Если не авторизован
      next({ name: 'login' });
    } else if (to.matched.some(record => record.meta.can)) { // Если маршрут требует разрешения
      let can = false;
      for (let i = 0; i < to.meta.can.length; i++) {
        if (user.permissions.indexOf(to.meta.can[i]) > -1) {
          can = true;
          break;
        }
      }

      if (can) {
        getBirthdayUsers();
        getTopUsers();
        next();
      } else {
        next({ name: 'notfound' });
      }
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) { // Если маршрут только для гостей
    if (loggedIn) { // Если авторизован
      next({ name: 'dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }
});

async function getBirthdayUsers() {
  try {
    const res = await BirthdayUsersService.getAll();
    if (res.data && res.data.length) {
      store.dispatch('setBirthdayUsers', res.data);
    }
  } catch (err) {
    console.log('getBirthdayUsers()', err);
  }
}

async function getTopUsers() {
  try {
    const res = await TopUsersService.getAll();
    if (res.data && res.data.length) {
      store.dispatch('setTopUsers', res.data);
    }
  } catch (err) {
    console.log('getTopUsers()', err);
  }
}

export default router;
